<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-02-07 16:17:43
 * @LastEditors: ------
 * @LastEditTime: 2022-02-09 14:19:18
-->
<template>
  <div class="info">
    <van-nav-bar
      title="客户信息"
      @click-left="$router.push('/')"
      left-arrow
      safe-area-inset-top
      placeholder
    />
    <div class="userInfo">
      <div class="title">
        <van-icon name="friends-o" size="24px" color="#3573FD" />
        <span> 客户信息</span>
      </div>
      <van-form>
        <van-field
          v-model="contract.name"
          name="用户姓名"
          label="用户姓名"
          placeholder="请输入用户姓名 >"
          input-align="right"
        />
        <van-field
          v-model="contract.mobile"
          name="用户手机号"
          label="用户手机号"
          placeholder="请输入用户手机号 >"
          type="digit"
          input-align="right"
        />
        <van-field
          v-model="contract.idCard"
          name="用户身份证"
          label="用户身份证"
          placeholder="请输入用户身份证 >"
          input-align="right"
        />
      </van-form>
      <div class="title">
        <van-icon name="location-o" size="24px" color="#3573FD" />
        <span>居住地址</span>
      </div>
      <van-form>
        <van-field
          readonly
          clickable
          name="area"
          :value="value"
          label="所在城市"
          placeholder="省/市/区 >"
          input-align="right"
          @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="contract.street"
          name="居住地址"
          label="居住地址"
          placeholder="请输入服务对象居住地址 >"
          input-align="right"
        />
      </van-form>
      <div class="button">
        <van-button
          color="linear-gradient(-90deg, #6EAEFC, #6875F1)"
          round
          @click="family"
          >下一步，创建家庭成员</van-button
        >
        <van-button color="#CACFD7" round @click="jump"
          >跳过，直接保存客户信息</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import { consumerInfo } from "@/api/index.js";



export default {
  data() {
    return {
      value: "",
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      contract: {
        consumerFamilyMemberList: [],
        name: "",
        mobile: "",
        idCard: "",
        status: "10",
        province: "",
        city: "",
        county: "",
        detailAddress: "",
        street: "",
        mycity: "",
        registSource: "3",
      },
    };
  },
 created() {
  // 
  let that = this;
  if(this.$store.state.contract){
      // console.log(this.$store.state.contract) 
   that.contract.name =   that.$store.state.contract.name
   that.contract.mobile =   that.$store.state.contract.mobile

  }else{
      console.log(this.$store.state.contract) 
  }
    // console.log(this.$store.contract) 
  },
  methods: {
    onConfirm(values) {
      //点击跳过保存时函数
      this.contract.county = values[2].code;
      this.contract.city = this.contract.county.slice(0, 4);
      this.contract.province = this.contract.county.slice(0, 2);
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("");
      this.contract.mycity = this.value;

      this.showArea = false;
    },
    async jump() {
      let that = this;
      this.contract.detailAddress = this.value + this.contract.street;
      if (
        this.contract.name &&
        this.contract.mobile &&
        this.contract.idCard &&
        this.value &&
        this.contract.street
      ) {
        const { data } = await consumerInfo(this.contract);
        if (data.success) {
          Toast.success(data.message);
           that.$store.commit("setContract", {});
          this.$router.push("/");
        } else {
          Toast.fail(data.message);
        }
      } else {
        Toast("请将信息补充完整！");
      }
    },
    family() {
      let that = this ;
      if (
        this.contract.name &&
        this.contract.mobile &&
        this.contract.idCard &&
        this.value &&
        this.contract.street
      ) {
        this.contract.detailAddress = this.value + this.contract.street;
        that.$store.commit("setContract", that.contract);
          // console.log(that.$store.state.contract) 
        this.$router.push({
          path: "/family",
          query: {
            info: JSON.stringify(this.contract),
          },
        });
      } else {
        Toast("请将信息填写完整！");
      }
    },
  },
};
</script>

<style scoped lang="less">
.info {
  height: 100vh;
  font-size: 15px;
  color: #333333;
  background-color: #f0f0f0;
  /deep/ .van-nav-bar {
    background: linear-gradient(-90deg, #5038fa, #1476ff);
    .van-nav-bar__title {
      color: #fff;
    }
  }
  /deep/ .van-nav-bar__left {
    .van-icon {
      color: #fff;
    }
  }
  .userInfo {
    .title {
      display: flex;
      align-items: center;
      margin: 12px 0 12px 16px;
      span {
        margin-left: 6px;
      }
    }
  }
  .button {
    width: 285px;
    margin: 30px auto;

    button {
      margin-top: 20px;
      width: 100%;
    }
  }
}
</style>
